import React from 'react'
import {Link} from 'gatsby' 
import uuid from 'uuid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faClock, faTag } from '@fortawesome/free-solid-svg-icons'


import BgImage from '../BgImage'

import "./style.sass"

const ActivitiesList = ({activities}) => (
  <ul className="activities-list">
    {activities.map(({node}, idx) => {
      return (
        <li key={uuid.v4()} className={'activiity-'+(idx+1)}>
          <Link to={node.frontmatter.path}>
            <div className="activity-pic">
              <BgImage className='room-slide' image={node.frontmatter.thumb}/>
            </div>
            <div className="activity-info">
              <h2>{node.frontmatter.title} - <span>{node.frontmatter.price} <span>/ {node.frontmatter.duration}</span></span></h2>
              <div className="activity-excerpt">{node.frontmatter.excerpt}</div>
              <div className="arrow-link">Read more details  <span>⟶</span></div>
            </div>
          </Link>
        </li>
      )
    })}
  </ul>  
)

export default ActivitiesList