import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from "../components/seo"
import BookingForm from "../components/BookingForm"
import ActivitiesList from '../components/ActivitiesList'
import PageBanner from '../components/PageBanner'
import banner_image from '../images/page-banners/activities.jpg'

const activitiesPage = ({data}) => {

  const {activities} = data

  return (

    <Layout sidebarComponent={<BookingForm colWidth="col-md-12" productType="Activity" />}>
      
      <SEO title="Activities" />

      <PageBanner image={banner_image} title="Activities" />

      <div className="inner-content">
        <ActivitiesList activities={activities.edges} />
      </div>

    </Layout>
    
  )
}

export default activitiesPage

export const activitiesPageQuery = graphql`
  query activitiesPageQuery {
    activities:
      allMarkdownRemark (
        filter: {
          frontmatter: {type: {eq: "Activity"}}
        }
        sort: { order: DESC, fields: [frontmatter___date]}
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              type
              excerpt
              path
              duration
              departure
              price
              thumb {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
  }
`